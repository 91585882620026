<div id="headBg">
  <div class="head-content">
    <div id="main-logo" (click)="goHome()">
      <img src="{{ logoSrc }}" alt="logo" />
    </div>
    <nav>
      <div class="connectionIssues">
        @for (issue of connectionIssuesIcon$|async; track issue) {
        <issue-icon [issue]="issue" (click)="openConnectionsIssuesModal()" />
        }
      </div>
      <a class="addContrat" (click)="addContract($event)">
        <span class="material-symbols-rounded">add</span>
        <span>Ajouter un contrat</span>
      </a>
      <a [routerLink]="['settings']">
        <div class="user-info">
          <span class="material-symbols-rounded">settings</span>
        </div>
      </a>

    </nav>

  </div>

</div>
