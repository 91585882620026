import { UrlSegment } from "@angular/router";
import { BankAccount, Connection, ConnectionState } from "./bi.interface";
import {
	Bank,
	BoConnection,
	Contract,
	Investment,
	RefFund
} from "./boost.interface";

/*****************************************************
 * CORE
 *****************************************************/

export interface UrlParams {
	contractId?: string;
	connectionId?: string;
	contractBIId?: string;
	tierContractId?: string;
	promoterId?: string;
	refContractId?: string;
	isin?: string;
}
export interface Segment extends UrlSegment {
	label: string;
}
export interface ThemeColors {
	[property: string]: string;
	"--default-primary": string;
}

export interface Theme {
	name: string;
	colors: ThemeColors;
	logo: string;
	logo_top: string;
	baseline?: string;
}

export interface SelectOption {
	label: string;
	value: string;
}

export enum Project {
	Retraite = "retraite",
	Education = "education",
	Immo = "immo",
	Precaution = "precaution",
	Legue = "legue",
	Valorisation = "valorisation"
}
/*****************************************************
 * DISPLAY API
 *****************************************************/

export interface ConnectionsWithIssue extends BoConnection {
	message?: string;
	icon?: string;
	color?: string;
}
export interface DisplayConnection extends ConnectionsWithIssue {
	isSelected?: boolean;
}

export interface DisplayBiAccount extends BankAccount {
	isSelected?: boolean;
	displayBalance?: string;

	accountToDisplay: boolean;
}
export interface DisplayContract extends Contract {
	isSelected?: boolean;

	accountToDisplay: boolean;
}

export interface SalarialYears {
	index: number;
	value: number;
	label: string;
	amount: string;
}

export interface ErrorIconsResume {
	icon: string;
	color: string;
	errortypes: ConnectionState[];
	count: number;
}

export interface ContractFilter {
	followers?: any;
	distributor?: number;
	risk?: number;
}

export interface AccountSumary {
	connection: Connection;
	contract: Contract;
	account: BankAccount;
}
export interface MixedAccount {
	contract: Contract;
	account: DisplayBiAccount;
}
export interface ConnectionSumary {
	bank: Bank;
	connection: Connection;
	mixedAccounts: MixedAccount[];
}

////

export interface ContractDisplay extends Contract {
	connectionError?: ConnectionErrorDisplay;
	error?: ConnectionState | null;
	bank?: Bank;
	investments?: Investment[];
}
export interface ConnectionErrorDisplay {
	error: ConnectionState | null;
	error_message: string | null;
}
export interface ConnectorBankDisplay {
	name: string | null;
	slug: string | null;
}
export interface AccountDisplay {
	disabled: Date | null;
	balance: number | null;
	displayBalance: string | null;
}

/*****************************************************
 * CARDS
 *****************************************************/

export interface CardTipImage {
	url: string;
	mode?: "full" | "center";
	width?: string;
	height?: string;
}

export interface Link {
	url: string;
	label: string;
}
export interface Cta extends Link {
	predefini: string;
}

interface CardTipBase {
	id: string;
	title: string;
	body: string;
	image?: CardTipImage;
}
export interface CardMarketing extends CardTipBase {
	cardContentType: "marketing";
	ctaPredefini?: "openWebView" | "openUrl" | "sponsorship";
	CTA?: Cta;
	type?: "classique" | "ui";
}
export interface CardInfo extends CardTipBase {
	cardContentType: "info";
	type?: "classique" | "mantra";
}
export type CardTip = CardTipBase & (CardMarketing | CardInfo);

/*****************************************************
 * FORMS
 *****************************************************/
export interface SubmitContractPayload {
	id?: number;
	connectionId?: number | null;
	bankId?: number;
	contractOPCVMId?: number;
	contractName?: string;
	freeManagement?: boolean;
	projects?: string[];
}

/*****************************************************
 * FUNDS
 *****************************************************/

export interface FundFilters {
	refContractId?: string;
	risk?: number;
	categories?: string[];
	owned?: boolean;
}
export interface DisplayFund extends RefFund {
	color: string;
	rgbaColor: string;

	varP1M: number;
	varP3M: number;
	varP6M: number;
	varP1Y: number;
	varP2Y: number;
	varP3Y: number;
	varP5Y: number;
	varP10Y: number;

	volat1Y: number;
	volat3Y: number;
	volat5Y: number;
}

/*****************************************************
 * CHARTS
 *****************************************************/
export interface AreaData {
	value: number;
	label: string;
	id: string | number;
	date: Date;
	dateFormated: string;
}

export interface LineData {
	value: number;
	date: Date;
}
export interface LineDataExtended extends LineData {
	label: string;
	id: string | number;
	dateFormated: string;
}
export interface PieData {
	value: number;
	label: string;
	id: string | number;
	earnings: number;
	earnings_formatted: string;
}
export interface StackData {
	value: number;
	label: string;
	id: string | number;
}
