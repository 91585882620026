@if ((loggedIn$ | async) || (profile$ | async)) {
<div class="page-container">
  @if (!openModal()) {
  <app-header />
  }
  <main class="main-content" id="main-content" #mainContent>
    <div>
      <router-outlet (activate)="onActivate($event)"></router-outlet>
      <router-outlet name="modal" (activate)="activateModal()" (deactivate)="deactivateModal()">
      </router-outlet>
    </div>

    @if ( !openModal()) {
    <app-footer></app-footer>
    }
  </main>
  <popin [show]="showConnectionsIssue()" (showChange)="closeModal()">
    <connections-Issues></connections-Issues>
  </popin>
  @if (showContractSettings(); as contract) {
  <popin [show]="!!contract" (showChange)="closeModal()">
    <header>
      <h1>{{contract.contractName}}</h1>
    </header>
    <main>
      <account-form [contract]=contract></account-form>
    </main>
  </popin>
  }
</div>
} @else {
<loading-box />
}
