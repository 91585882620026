import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { UserService } from "@services/API/user.service";
import { first } from "rxjs/operators";

@Component({
    selector: "app-impersonate",
    templateUrl: "./impersonate.component.html",
    styleUrls: ["./impersonate.component.scss"],
    standalone: true
})
export class ImpersonateComponent implements OnInit {
	private email!: string;

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private userService: UserService
	) {
		this.route.params.subscribe(params => (this.email = params["email"]));
	}

	ngOnInit() {
		console.log(this.email);
		this.userService
			.impersonate(this.email)
			.pipe(first())
			.subscribe({
				next: () => {
					this.router.navigate(["/"]);
				},
				error: error => {
					console.log(error);
				}
			});
	}
}
