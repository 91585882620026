import { Component, OnInit } from "@angular/core";

@Component({
    selector: "cgu-content",
    templateUrl: "./cgu.content.html",
    standalone: true
})
export class CguContentComponent implements OnInit {
	constructor() {}

	ngOnInit() {}

	public scrollToElement($element: any): void {
		console.log($element);
		$element.scrollIntoView({
			behavior: "smooth",
			block: "start",
			inline: "nearest"
		});
	}
}
