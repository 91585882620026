@if (mainLoading) {
<loading-box />
} @else {
@if (connectionError) {
<connection-issue-feedback class="portrait" />
}
<form [formGroup]="form">
  <boost-input [form]="form" [type]="'text'" [controlName]="'contractName'" [label]="'Nom du contract'"
    [autofocus]="true"></boost-input>
  @if (isSalarialAccount) {
  <boost-input [form]="form" [type]="'text'" [controlName]="'company'" [label]="'Entreprise'"></boost-input>
  }
  <!-- <boost-select [form]="form" [controlName]="'produit'" [label]="'Produit'"
  [items]="(produitOption$ | async)??[]"></boost-select> -->
  <boost-select [form]="form" [controlName]="'projects'" [label]="'Projets'" [items]="projectOptions"></boost-select>
  <!-- <div>
  <boost-checkbox #freeManagementCB [form]="form" [controlName]="'freeManagement'"
  [label]="'Gestion libre'"></boost-checkbox>
  <span [ngClass]="{'loading':loading}" class="loader primary full"></span>
</div> -->
  <!-- <boost-submit-button [form]="form" [loading]="loading" [label]="'Se connecter'"></boost-submit-button>-->
</form>
@if (!contract.projects || !contract.projects.length) {
<div class="missing">
  <strong class="conError"></strong>
  <p>Ça serait cool d'indiquer le projet associé à ce contrat, comme ça on fera plein de recoupements et tout.</p>
</div>
}
<div class="feedback {{feedback}}">
  <span class="feed_danger">Une erreur est survenue, veuillez réésayer plus tard.</span>
  <span class="feed_success">Contract mis à jour avec success :)</span>
</div>
}
