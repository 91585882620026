import { Injectable, inject } from "@angular/core";
import { CardTip } from "@interfaces";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { StrapiService } from "@src/app/core/services/API/strapi.service";
import { catchError, map, mergeMap, of } from "rxjs";
import { TipActionTypes, actions } from "./tip.actions";

@Injectable()
export class TipEffects {
	private actions$ = inject(Actions);
	private STRAPI = inject(StrapiService);

	// LOAD TIPS
	public loadTips$ = createEffect(() =>
		this.actions$.pipe(
			ofType(TipActionTypes.LOAD_TIPS),
			mergeMap(() => this.STRAPI.getAllTips()),
			map((tips: CardTip[]) => {
				return actions.loadTipsSuccess({ tips });
			}),
			catchError(error => {
				return of(actions.loadTipsFailure({ error }));
			})
		)
	);
}
