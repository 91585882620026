import { Component, computed, input } from "@angular/core";
import { ErrorIconsResume } from "@interfaces";

@Component({
	standalone: true,
	selector: "issue-icon",
	styleUrls: ["./issue-icon.component.scss"],
	template: `
		@if (showIcon()) { @if (resume().icon) {
		<span
			class="
      material-symbols-rounded{{ resume().count ? ' count' : ' ' }}
      {{ resume().color }}
      {{ resume().icon }}
      "
		>
			{{ resume().icon }}
		</span>
		} @if (resume().count) {
		<strong class="{{ resume().color }}">{{ resume().count }}</strong>
		} }
	`
})
export class IssueIconComponent {
	issue = input<ErrorIconsResume>();
	color = input<string>();
	icon = input<string>();
	count = input<number>();
	resume = computed(() => {
		const { issue, color, icon, count } = this;
		return {
			...issue(),
			...(!!icon() && { icon: icon() }),
			...(!!color() && { color: color() }),
			...(!!count() && { count: count() })
		};
	});
	showIcon = computed(() => !!this.resume().color && !!this.resume().icon);
}
