import { CardTip } from "@interfaces";
import { createReducer, on } from "@ngrx/store";
import { actions } from "./tip.actions";

export interface TipState {
	error: any;
	loading: boolean;
	tips: CardTip[];
}

const initialState: TipState = {
	error: null,
	loading: false,
	tips: []
};

export const tipReducer = createReducer(
	initialState,
	on(actions.loadTips, state => ({ ...state, loading: true })),
	on(actions.loadTipsSuccess, (state, { tips }) => ({
		...state,
		tips,
		loading: false
	})),
	on(actions.loadTipsFailure, (state, { error }) => ({
		...state,
		error,
		loading: false
	}))
);
