import { Component, Input, OnInit } from "@angular/core";
import {
	FormControl,
	FormGroup,
	FormsModule,
	ReactiveFormsModule,
	UntypedFormBuilder
} from "@angular/forms";
import {
	AccountTypeName,
	Contract,
	ContractDisplay,
	Project,
	SelectOption,
	SubmitContractPayload
} from "@interfaces";
import { ContractService } from "@services/API/contract.service";

import {
	filterSalarialAccountType,
	objectDiff,
	toInt
} from "@services/utils/utils.service";
import { filter, map, take, takeWhile, tap } from "rxjs/operators";
import { StoreService } from "../../../core/services/store.service";
import { ConnectionIssueFeedbackComponent } from "../../components/connection-issue-feedback/connection-issue-feedback.component";
import { LoadingBoxComponent } from "../../components/loading-box.component";
import { BoostInputComponent } from "../boost-input.component";
import { BoostSelectComponent } from "../boost-select.component";
import { ConnectionsIssuesComponent } from "../connections-Issues/connections-Issues.component";

export const PROJECT_OPTIONS: { label: string; value: Project }[] = [
	{
		label: "Préparer ma retraite",
		value: Project.Retraite
	},
	{
		label: "Epargner pour l'éduction de mes enfants",
		value: Project.Education
	},
	{
		label: "Préparer un achat immobilier",
		value: Project.Immo
	},
	{
		label: "Constituer une épargne de précaution",
		value: Project.Precaution
	},
	{
		label: "Léguer un capital",
		value: Project.Legue
	},
	{
		label: "Valoriser mon capital",
		value: Project.Valorisation
	}
];

@Component({
	standalone: true,
	imports: [
		FormsModule,
		ReactiveFormsModule,
		BoostSelectComponent,
		BoostInputComponent,
		ConnectionsIssuesComponent,
		ConnectionIssueFeedbackComponent,
		LoadingBoxComponent
	],
	selector: "account-form",
	templateUrl: "./account-form.component.html",
	styleUrls: ["./account-form.component.scss"]
})
export class AccountFormComponent implements OnInit {
	@Input() contract!: Contract;
	public feedback!: "success" | "danger" | null;
	public mainLoading: boolean = true;
	public isSalarialAccount: boolean = false;
	private alive = true;
	private accountData: SubmitContractPayload | null = null;
	public connectionError: boolean = false;
	public projectOptions: SelectOption[] = PROJECT_OPTIONS;

	public form: FormGroup<{
		contractName: FormControl<string>;
		projects: FormControl<string>;
		company: FormControl<string>;
		freeManagement: FormControl<boolean>;
	}> = this.fb.group({
		contractName: [
			null,
			{ updateOn: "blur", validators: [], nonNullable: true }
		],
		company: [null, { updateOn: "blur", validators: [], nonNullable: true }],
		projects: [null, { updateOn: "blur", validators: [] }],
		freeManagement: [false, { updateOn: "blur", validators: [] }]
	});

	constructor(
		private CONTRACT: ContractService,
		private fb: UntypedFormBuilder,
		private STORE: StoreService
	) {}

	ngOnInit() {
		this.STORE.displayContractById(this.contract?.id)
			.pipe(
				filter((contract: ContractDisplay | undefined) => !!contract),
				tap(contract => {
					this.isSalarialAccount = filterSalarialAccountType(
						contract?.type as AccountTypeName
					);
					this.connectionError = !!contract?.error;
				}),
				map(contract => ({
					id: toInt(contract?.id as string),
					connectionId: contract?.connectionId,
					contractName: contract?.contractName ?? "",
					contractOPCVMId: contract?.contractOPCVMId,
					freeManagement: contract?.freeManagement,
					projects: Array.isArray(contract?.projects)
						? contract?.projects[0]
						: contract?.projects,
					bankId: toInt(contract?.Bank?.id as string),
					company: contract?.company ?? null
				})),
				takeWhile(() => this.alive)
			)
			.subscribe(payload => {
				const { contractName, projects, freeManagement } = payload;
				this.accountData = {
					...payload,
					projects: [projects]
				} as SubmitContractPayload;
				this.mainLoading = false;
				// set form values

				this.form.patchValue({
					contractName,
					projects,
					freeManagement
				});
			});

		// validate on blur
		this.form.valueChanges
			.pipe(
				filter(
					vals =>
						this.form.valid && objectDiff(vals, this.accountData).length > 0
				),
				takeWhile(() => this.alive)
			)
			.subscribe(() => this.checkAndPost());
	}

	private checkAndPost() {
		const values = this.form.value;
		const project = values.projects;

		if (!this.accountData) return;

		const payLoad = {
			...this.accountData,
			...values,
			projects: project ? [project] : []
		};

		this.CONTRACT.saveContract(payLoad)
			.pipe(take(1))
			.subscribe(
				() => {
					this.accountData = payLoad;
					this.CONTRACT.getContractAction(this.contract.id);
					this.feedback = "success";
					setTimeout(() => (this.feedback = null), 3000);
				},
				(err: Error) => {
					console.error("something wrong occurred: " + err);
					this.feedback = "danger";
					setTimeout(() => (this.feedback = null), 3000);
				}
			);
	}

	ngOnDestroy() {
		this.alive = false;
	}
}
