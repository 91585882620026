import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { CguContentComponent } from "./cgu/cgu.content";
import { NotFoundComponent } from "./core/NotFoundComponent.page.component";
import { AuthGuard } from "./core/guards/auth.guard";
import { ImpersonateComponent } from "./impersonate/impersonate.component";

const routes: Routes = [
	// WTF

	{ path: "impersonate/:email", component: ImpersonateComponent },

	{ path: "cgu", component: CguContentComponent },

	/* SETTINGS */
	{
		path: "settings",
		canActivate: [AuthGuard],
		loadChildren: () =>
			import("./settings/settings.module").then(m => m.SettingsModule)
	},

	/* CONTRACTS  */
	{
		path: "contract",
		loadChildren: () =>
			import("./contracts/contracts.module").then(m => m.ContractsModule)
	},

	/* FUNDS */

	// {
	// 	path: "fund",
	// 	canActivate: [AuthGuard],
	// 	outlet: "modal",
	// 	loadChildren: () => import("./funds/funds.module").then(m => m.FundsModule)
	// },

	/* DEFAULT */

	{
		path: "dashboard",
		canActivate: [AuthGuard],
		pathMatch: "full",
		loadChildren: () =>
			import("./dashboard/dashboard.module").then(m => m.DashboardModule)
	},
	{
		path: "",
		pathMatch: "full",
		redirectTo: "dashboard"
	},
	// { path: "**", redirectTo: "dashboard" },
	{ path: "404", component: NotFoundComponent },
	{ path: "**", redirectTo: "/404" }
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			scrollPositionRestoration: "enabled",
			useHash: false,
			anchorScrolling: "enabled",
			preloadingStrategy: PreloadAllModules
			//	enableTracing: true
		})
	],
	exports: [RouterModule]
})
export class AppRoutingModule {}
