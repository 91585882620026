import { Injectable, Signal, WritableSignal, signal } from "@angular/core";

@Injectable({ providedIn: "root" })
export class NavigationService {
	/*****************************************************
	 * MODAL
	 *****************************************************/

	private readonly _openModal: WritableSignal<boolean> = signal(false);

	public set openModal(val: boolean) {
		this._openModal.set(val);
	}
	public get openModal(): Signal<boolean> {
		return this._openModal;
	}

	/*****************************************************
	 * CONNECTIONS & ISSUES
	 *****************************************************/
	// Show issues
	private readonly _showConnectionsIssues: WritableSignal<boolean> =
		signal(false);

	public set showConnectionsIssues(val: boolean) {
		this._showConnectionsIssues.set(val);
	}
	public get showConnectionsIssues(): Signal<boolean> {
		return this._showConnectionsIssues;
	}

	/*****************************************************
	 *
	 *****************************************************/
	// 	public _routeParams: BehaviorSubject<any> = new BehaviorSubject(null);
	// 	public routeParams$: Observable<any> = this._routeParams.asObservable();
	// 	public get routeParams() {
	// 		return this._routeParams.getValue();
	// 	}
	// 	public set routeParams(params: any) {
	// 		this._routeParams.next({ ...this.routeParams, ...params });
	// 	}

	// public refreshConnections() {
	// 	// this.BI.refresh();
	// }
}
