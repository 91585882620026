import { Injectable, inject } from "@angular/core";
import { BoConnection } from "@interfaces";
import { Actions, createEffect, ofType } from "@ngrx/effects";

import { BudgetinsightService } from "@src/app/core/services/API/budgetinsight.service";
import { catchError, map, mergeMap, of, switchMap } from "rxjs";
import { ConnectionActionTypes, actions } from "./connection.actions";

@Injectable()
export class ConnectionEffects {
	private actions$ = inject(Actions);
	private BI = inject(BudgetinsightService);

	public loadConnections$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ConnectionActionTypes.LOAD_CONNECTIONS),
			switchMap(() => this.BI.getAllConnections),
			map((connections: BoConnection[]) => {
				return actions.loadConnectionsSuccess({ connections });
			}),
			catchError(error => {
				return of(actions.loadConnectionsFailure({ error }));
			})
		)
	);

	// UPDATE CONNECTION
	public updateConnection$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ConnectionActionTypes.UPDATE_CONNECTION),
			mergeMap(({ id }) => this.BI.getConnectionById(id)),
			map(({ data }) => data?.Connection),
			map((connection: BoConnection) => {
				return actions.updateConnectionSuccess({
					update: { id: connection.id, changes: connection }
				});
			}),
			catchError(error => {
				return of(actions.updateConnectionFailure({ error }));
			})
		)
	);
}
