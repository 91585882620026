import { Component } from "@angular/core";
import dayjs from "dayjs";
@Component({
	standalone: true,
	selector: "app-footer",
	template: `
		<div>
			<p>Copyright © {{ year }} Boost Epargne Tous droits réservés.</p>
			<!-- <a href="#" [routerLink]="['/terms']">Mentions légales</a> -->
		</div>
	`,
	styleUrls: ["./footer.component.scss"]
})
export class FooterComponent {
	year = dayjs().format("YYYY");
}
