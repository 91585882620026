import { Injectable, inject } from "@angular/core";
import { BankAccount, Contract } from "@interfaces";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { BudgetinsightService } from "@src/app/core/services/API/budgetinsight.service";
import { ContractService } from "@src/app/core/services/API/contract.service";
import { catchError, exhaustMap, map, mergeMap, of, take } from "rxjs";
import { ContractActionTypes, actions } from "./contract.actions";

@Injectable()
export class ContractEffects {
	private BI = inject(BudgetinsightService);
	private actions$ = inject(Actions);
	private CONTRACT = inject(ContractService);

	public loadUserContracts$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ContractActionTypes.LOAD_CONTRACTS),
			// switchMap(() => this.CONTRACT.getAllContracts()),
			exhaustMap(() => this.CONTRACT.getDashboard()),
			map((contracts: Contract[]) => {
				return actions.loadContractsSuccess({ contracts });
			}),
			catchError(error => {
				return of(actions.loadContractsFailure({ error }));
			})
		)
	);

	// UPDATE CONTRACT
	public updateContract$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ContractActionTypes.UPDATE_CONTRACT),
			mergeMap(({ id }) => this.CONTRACT.getContract(id, false)),
			map(({ data }) => data?.Contract),
			map((contract: Contract) => ({
				...contract,
				investments: contract.ContractLastUpdate?.investments
			})),
			map((contract: Contract) => {
				return actions.updateContractSuccess({
					contract
				});
			}),
			catchError(error => {
				return of(actions.updateContractFailure({ error }));
			})
		)
	);

	public loadContractValue$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ContractActionTypes.LOAD_CONTRACT_VALUES),
			mergeMap(({ id, limit }) =>
				this.CONTRACT.getContractValues(id, limit).pipe(
					take(1),
					map(({ data }) => data?.Contract),
					map((contract: Contract) =>
						actions.loadContractValuesSuccess({
							update: { id: contract.id, changes: contract }
						})
					),
					catchError(error => {
						return of(actions.loadContractValuesFailure({ error }));
					})
				)
			)
		)
	);

	public updateContractsActive$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ContractActionTypes.UPDATE_CONTRACTS_ACTIVE),
			mergeMap(() =>
				this.BI.allAccount$.pipe(
					take(1),
					map((accounts: BankAccount[]) =>
						actions.updateContractsActiveSuccess({ accounts })
					),
					catchError(error => {
						return of(actions.updateContractsActiveFailure({ error }));
					})
				)
			)
		)
	);
}
