import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { InMemoryCache } from "@apollo/client/core";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { environment } from "@environments/environment";
import {
	APOLLO_NAMED_OPTIONS,
	ApolloModule,
	NamedOptions
} from "apollo-angular";
import { HttpLink } from "apollo-angular/http";

const authLink = setContext((_, { headers }) => {
	const token = localStorage.getItem("currentUser");
	return {
		headers: {
			...headers,
			Authorization: token ? `Bearer ${token}` : ""
		}
	};
});
const error = onError(({ graphQLErrors, networkError, operation, forward }) => {
	console.log(graphQLErrors);
	if (graphQLErrors) {
		for (let err of graphQLErrors) {
			if (err.extensions.code === "UNAUTHENTICATED") {
				return forward(operation);
			}
		}
	}
	if (networkError) {
		console.log(`[Network error]: ${networkError}`);
	}
	return;
});

@NgModule({
	imports: [BrowserModule, ApolloModule, HttpClientModule],
	providers: [
		{
			provide: APOLLO_NAMED_OPTIONS,
			useFactory(httpLink: HttpLink): NamedOptions {
				const http = httpLink.create({ uri: environment.apiUrl });
				const errorConcat = error.concat(http);
				return {
					mainAPI: {
						link: authLink.concat(errorConcat),
						cache: new InMemoryCache(),
						defaultOptions: {
							watchQuery: {
								errorPolicy: "all"
							}
						}
					},
					strapiAPI: {
						cache: new InMemoryCache(),
						link: httpLink.create({
							uri: environment.strapiUrl
						})
					}
				};
			},
			deps: [HttpLink]
		}
	]
})
export class GraphQLModule {}
