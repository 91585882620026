<article class="legal">
  <header>
    <h1>Conditions générales d'utilisation</h1>
    <em>Version en vigueur au 01/04/2022</em>
  </header>
  <section id="intro">
    <!-- commun 1 -->
    <p>BE Epargne - SAS au capital entièrement libéré de 20 000 euros</p>
    <p> <br>
      <strong> Siège social :</strong>
      48 rue Danton
      <br> 92300 Levallois
      <br> 822 455 077 RCS Nanterre
    </p>
    <p> <br>
      <strong>Adresse de correspondance :</strong>
      <br> 48 rue Danton
      <br>92300 Levallois.
    </p>
    <p>BE Epargne est l’éditeur de la solution Boost’Epargne©</p>
    <!-- fin commun 1 -->

    <br>
    <!-- commun 2 -->

    <p>BE Epargne est immatriculé au Registre Unique des Intermédiaire en Assurance, Banque et Finance (ORIAS) sous le
      n°
      d’ <strong>immatriculation 17002172 </strong> <br>(Vous pouvez vérifier cette immatriculation sur le site internet
      ORIAS :
      <a href="https://www.orias.fr/welcome"> orias.fr</a>)
    </p>
    <p>BE Epargne est inscrit en tant qu'agent auprès de l'ACPR sous le <strong>numéro 14620</strong> :
      <a
        href="https://www.regafi.fr/spip.php?type=simple&id_secteur=1&lang=fr&denomination=be+epargne&page=af&id=14620">regafi.fr</a>
    </p>
    <p>BE Epargne a souscrit une assurance auprès d'AON en tant qu'Intermédiaire Financiers et en assurance (
      <strong>Police N°
        7400026945</strong> ).
    </p>
    <!-- fin commun 2 -->
    <br>
    <p>L’espace Client (ci-après le « Service ») est l’espace à destination des clients de Boost’Epargne accessible
      depuis un terminal connecté. En acceptant les présentes Conditions Générales d’Utilisation du Service, (ci-après
      « l’Utilisateur ») désignée par le terme « CGU », l’Utilisateur s’engage à les respecter.
    </p>
    <p>L’éditeur du Service se réserve le droit de modifier les présentes CGU à tout moment afin, notamment, de les
      adapter aux évolutions du Service. Chaque modification des présentes CGU fera l’objet d’une présentation au client
      pour acceptation préalable.
    </p>
  </section>
  <section>
    <h2>Sommaire</h2>
    <nav>

      <ul>
        <li (click)="scrollToElement(p1)">
          <span>1.</span><span>Le service</span>
        </li>
        <ul>
          <li (click)="scrollToElement(p11)">
            <span>1.1</span>
            <span>Accès au service</span>
          </li>
          <ul>
            <li (click)="scrollToElement(p111)">
              <span>1.1.1</span><span>Cadre contractuel général</span>
            </li>
            <li (click)="scrollToElement(p112)">
              <span>1.1.2</span><span>Prérequis techniques</span>
            </li>
          </ul>
          <li (click)="scrollToElement(p12)">
            <span>1.2</span><span>Connexion au service</span>
          </li>
          <li (click)="scrollToElement(p13)">
            <span>1.3</span><span>Principes de sécurité</span>
          </li>
          <li (click)="scrollToElement(p14)">
            <span>1.4</span><span>Disponibilité du service</span>
          </li>
        </ul>
        <li (click)="scrollToElement(p2)">
          <span>2.</span><span>Fonction du service</span>
        </li>
        <ul>
          <li (click)="scrollToElement(p21)">
            <span>2.1</span><span>Fonctions accessibles du service</span>
          </li>
          <ul>
            <li (click)="scrollToElement(p211)">
              <span>2.1.1</span><span>Réception de push mails</span>
            </li>
            <li (click)="scrollToElement(p212)">
              <span>2.1.2</span>
              <span>Messagerie Client ou Formulaire de contact</span>
            </li>
            <li (click)="scrollToElement(p213)">
              <span>2.1.3</span><span>E-Documents</span>
            </li>
          </ul>
          <li (click)="scrollToElement(p22)">
            <span>2.2</span>
            <span>La signature électronique</span>
          </li>
        </ul>
        <li (click)="scrollToElement(p3)">
          <span>3.</span><span>Tarification</span>
        </li>
        <li (click)="scrollToElement(p4)">
          <span>4.</span><span>Droits de propriété intellectuelle</span>
        </li>
        <li (click)="scrollToElement(p5)">
          <span>5.</span>
          <span>Protection des données personnelles / secret professionnel</span>
        </li>
        <ul>
          <li (click)="scrollToElement(p51)">
            <span>5.1</span><span>Protection des données personnelles</span>
          </li>
          <ul>
            <li (click)="scrollToElement(p511)">
              <span>5.1.1</span><span>Responsable du traitement et finalités</span>
            </li>
            <li (click)="scrollToElement(p512)">
              <span>5.1.2</span><span>Caractéristiques sur l'usage et la collecte de données</span>
            </li>
            <li (click)="scrollToElement(p513)">
              <span>5.1.3</span><span>Durée de conservation des données</span>
            </li>
            <li (click)="scrollToElement(p514)">
              <span>5.1.4</span><span>Modalités d'exercice des droits de
                l’Utilisateur</span>
            </li>
            <li (click)="scrollToElement(p515)">
              <span>5.1.5</span><span>Destinataires des données</span>
            </li>
            <li (click)="scrollToElement(p516)">
              <span>5.1.6</span><span>Utilisation de vos données à des fins de prospection</span>
            </li>
          </ul>
          <li (click)="scrollToElement(p52)">
            <span>5.2</span><span>Secret professionnel</span>
          </li>
        </ul>
        <li (click)="scrollToElement(p6)">
          <span>6.</span><span>Responsabilité de l’Utilisateur</span>
        </li>
        <li (click)="scrollToElement(p7)">
          <span>7.</span><span>Modification du service</span>
        </li>
        <li (click)="scrollToElement(p8)">
          <span>8.</span><span>Clôture du service</span>
        </li>
        <li (click)="scrollToElement(p9)">
          <span>9.</span><span>Renseignement, réclamation et médiation</span>
        </li>
        <ul>
          <li (click)="scrollToElement(p91)">
            <span>9.1</span><span>Renseignements, réclamations</span>
          </li>
          <li (click)="scrollToElement(p92)">
            <span>9.2</span><span>Saisie du médiateur</span>
          </li>
        </ul>
        <li (click)="scrollToElement(p10)">
          <span>10.</span><span>Loi applicable et compétence du tribunal</span>
        </li>
      </ul>

    </nav>
    <p>
      Dans le cadre des relations commerciales entre le prospect ou le client et Boost’Epargne, un choix lui a été
      présenté entre une poursuite de la relation en mode papier, ou en mode dématérialisé, via le présent espace
      client. Le client est informé qu'il peut à tout moment interrompre la relation dématérialisée, et demander un
      retour à une communication et des échanges au format papier.
      <br> En cas d'adhésion ou de souscription de contrat d'assurance via le présent espace client, le client ou
      prospect se voit offrir un choix entre une conclusion au format papier, ou au format électronique via
      l'utilisation du présent site. Lorsque le client ou prospect opte pour cette dernière modalité, un courriel
      d'activation lui est adressé. Le fait d'activer l'espace client manifeste, au sens des présentes CGU, la volonté
      non équivoque du prospect ou client de choisir la souscription en mode électronique.

    </p>

  </section>


  <section>
    <h2 #p1><span>1.</span>Le service</h2>
    <h3 #p11><span>1.1</span>Accès au service</h3>

    <h4 #p111><span>1.1.1</span>Cadre contractuel général</h4>
    <p>
      Le Service est réservé aux prospects et aux clients de Boost’Epargne détenteurs d’un abonnement au service de
      Boost’Epargne en cours ou non.
      <br> Ces titulaires ou utilisateurs seront indifféremment désignés ci-après par le terme « l’Utilisateur ».
      <br> L’utilisation du Service est régie par les stipulations des contrats suivants auxquels l’Utilisateur a
      préalablement et le cas échéant souscrit :
      <br> Il est par ailleurs précisé que les conditions d’exécution des opérations réalisées par l’Utilisateur dans le
      cadre des fonctions du Service telles que décrites dans l’article 2 des présentes sont régies par tout contrat /
      document spécifique à l’opération exécutée signé préalablement entre l’Utilisateur et Boost’Epargne.

    </p>
    <h4 #p112><span>1.1.2</span>Prérequis techniques</h4>
    <p>
      L’accès au Service nécessite l’utilisation d’un terminal disposant d’une connexion à Internet via le réseau d’un
      opérateur téléphonique ou d’une connexion Internet sans fil. La connexion en wifi doit être à haut débit et la
      connexion via réseau mobile nécessite au minimum un abonnement en 4G.
      <br> L’Utilisateur fait son affaire personnelle de la détention dudit terminal mobile ainsi que de sa mise en
      service, de sa maintenance et de son paramétrage ainsi que du raccordement de ce terminal à une connexion Internet
      adaptée. La responsabilité de l’éditeur ne peut être recherchée en raison d’une couverture réseau ou wifi
      insuffisante, ni en cas d’inadaptation du terminal utilisé.

    </p>

    <h3 #p12><span>1.2</span>Connexion au service</h3>
    <p>
      Pour des raisons de sécurité et de confidentialité, la connexion au Service et au Profil de l’Utilisateur
      nécessite la saisie d’un couple Identifiant – Mot de passe toutes les 15 minutes d’inactivité. Il s’agit d’un time
      out de sécurité et donc d’une nouvelle saisie.
      <br> Boost’Epargne peut également être amenée à appliquer l’authentification de l’Utilisateur dans le cadre de
      l’utilisation du Service, notamment son authentification forte conformément à la règlementation en vigueur, par
      l’utilisation de dispositifs de sécurité spécifiques mis en place par Boost’Epargne (identifiants et codes
      d’accès, code temporaire à usage unique par SMS, application mobile, certificat électronique, etc.) qui peuvent
      varier en fonction de la sensibilité des opérations proposées ou des exigences réglementaires en vigueur.

    </p>
    <h3 #p13><span>1.3</span>Principes de sécurité</h3>
    <p>
      L’Utilisateur s’engage à protéger et conserver secrets, et en conséquence à ne communiquer à quelque tiers que ce
      soit, son mot de passe et l’un quelconque des identifiants d’accès ou de tout autre dispositif de sécurité
      spécifique qui lui aurait été communiqué par Boost’Epargne dans le cadre de l’utilisation du Service.
      L’Utilisateur assume seul les conséquences de la divulgation de son mot de passe et de ses identifiants d’accès ou
      de tout autre dispositif de sécurité spécifique qui lui aurait été communiqué par Boost’Epargne.
      <br> L’Utilisateur s’engage à informer, sans délai, Boost’Epargne de toute atteinte à la confidentialité, perte ou
      anomalie constatée concernant ceux-ci. Tout changement de son code d’accès est sous la responsabilité exclusive de
      l’Utilisateur.
      <br> L’Utilisateur reconnaît que les actions effectuées sur son compte lui seront imputées lorsque ses
      identifiants d’accès (ou tout autre dispositif de sécurité spécifique qui lui aurait été communiqué par
      Boost’Epargne) auront été activés, en l’absence de toute demande de révocation de sa part (celle-ci devant être
      effectuée selon les modalités qui lui ont été préalablement indiquées lors la remise de ses identifiants d’accès,
      ou lors de la communication du dispositif de sécurité spécifique).
      <br> Le mot de passe Utilisateur est sauvegardé de façon cryptée dans les serveurs informatiques de Boost’Epargne
      et ne peut être connu de tiers


    </p>
    <h3 #p14><span>1.4</span>Disponibilité du service</h3>
    <p>
      Le Service est accessible 24 heures sur 24, 7 jours sur 7, sauf cas de force majeure, fait(s) de tiers et/ou
      travaux de maintenance et interventions nécessaires à son bon fonctionnement. Le Service pourra être
      ponctuellement interrompu pour des raisons d’ordre technique, notamment pour effectuer des opérations de
      maintenance correctives, ou pour des raisons de sécurité. En cas d'interruption du service pour quelque cause que
      ce soit, l'Utilisateur a toujours la possibilité de s'adresser à son agence pour effectuer ses opérations.


    </p>
  </section>




  <section>
    <h2 #p2><span>2.</span>Fonction du service</h2>
    <p>En utilisant le Service, l’Utilisateur pourra accéder à tout ou partie des fonctions décrites au présent article.
      Celles-ci peuvent varier en fonction du navigateur du terminal de l’Utilisateur, de l’agence Boost’Epargne dont il
      est client, du Profil (Profil « Particulier » ou « Professionnel »).</p>

    <h3 #p21><span>2.1</span>Fonctionnalités du service</h3>
    <p>

      Le Service permet à l'Utilisateur, en sa qualité d'adhérent, de souscripteur, ou de représentant légal (pour les
      personnes morales) de réaliser diverses opérations, et notamment de :
    </p>
    <ul>
      <li>consulter les informations essentielles concernant les contrats éligibles en cours</li>
      <li> souscrire ou adhérer à un contrat d'épargne via le dispositif de signature électronique (uniquement pour les
        contrats au titre desquels cette fonctionnalité est disponible)</li>
      <li>consulter les contrats signés électroniquement sur le présent Espace client</li>
      <li>accéder à des informations relatives aux supports disponibles et liés aux contrats éligibles</li>
    </ul>
    <p>Les opérations réalisables sont susceptibles d’évolution en fonction des évolutions du Service, de la
      règlementation, ou des règles de sécurité ou dans le cadre de la convention conclue par ailleurs entre
      Boost’Epargne et l’Utilisateur. Dans tous les cas, Boost’Epargne en informera l’Utilisateur par tout moyen.
    </p>


    <h4 #p211><span>2.1.1</span>Réception de push mails</h4>
    <p>L’utilisateur pourra recevoir les notifications push sur son adresse mail dans le cadre de l’utilisation du
      Service pour l’informer :
    </p>
    <ul>
      <li>Des évolutions (variations, modifications etc.) de ses contrats inscrits et ceux suivis)
      </li>
      <li>Des évolutions des supports détenus ou non</li>
      <li>d'une information d’ordre commercial envoyée par votre agence Boost’Epargne, sous réserve que le client l'ait
        accepté dans l'opt-in.
      </li>
    </ul>

    <h4 #p212><span>2.1.2</span>Messagerie Client ou Formulaire de contact</h4>
    <p>

      Le Service peut permettre à l’Utilisateur d’accéder à une messagerie, ci-après dénommée « Formulaire de contact »,
      strictement réservée aux échanges entre Boost’Epargne et l’Utilisateur.
      <br>Le Formulaire de contact permet notamment à l’Utilisateur d’envoyer des demandes d’informations, de
      modifications générales sur ses produits et services, ainsi que ses coordonnées, des messages commerciaux.
      <br>A ce titre, l’Utilisateur s’abstiendra de transmettre tout message ne s’inscrivant pas directement dans le
      cadre de celle-ci. En outre, l’Utilisateur s’engage à faire un usage raisonnable du Formulaire de contact
      (fréquence d’envoi de messages, contenu, etc.) et est informé que celle-ci est assujettie à des contraintes
      techniques (capacité de stockage 09Mo) et de sécurité susceptibles d’évolutions.
      <br>Pour toute autre demande formulée via le Formulaire de contact, Boost’Epargne reste libre d’accepter sa prise
      en compte et, lorsqu’elle l’accepte, de l’exécuter dans les délais demandés par l’Utilisateur. Il est notamment
      précisé que la prise en compte des demandes de mise à jour des données et informations personnelles de
      l’Utilisateur est toujours conditionnée à la présentation de pièces justificatives dont la prise en compte est
      soumise aux contrôles et vérifications de Boost’Epargne.

    </p>

    <h4 #p213><span>2.1.3</span>E-Documents</h4>
    <p>
      Le Service peut permettre à l’Utilisateur d’accéder à certains documents au format électronique, ci-après désignés
      sous le terme « e-Documents ».
      <br> La liste des e-Documents pouvant être disponibles au sein du Service est susceptible d’évolution. Les
      documents électroniques présents au sein du Service sont librement consultables et peuvent être enregistrés par
      l’Utilisateur sur ses espaces de stockage personnels. L’Utilisateur est néanmoins informé que ces documents ne
      sont mis à disposition et historisés au sein du Service pendant toute la durée du contrat augmentée de 5 ans. En
      conséquence, il prendra toutes les dispositions nécessaires pour procéder à l’enregistrement ou à la conservation,
      par tous les moyens qu’il jugera adaptés, des documents électroniques mis à sa disposition.
      <br> L’Utilisateur s'engage à signaler immédiatement à Boost’Epargne toute anomalie qu’il constaterait dans le
      contenu des e-Documents. Il s'engage également à signaler au plus tôt les éventuelles difficultés d'accès
      rencontrées afin de permettre à Boost’Epargne de maintenir la qualité du Service. En cas de difficulté technique
      rendant impossible l’accès aux e-Documents, Boost’Epargne pourra procéder, dès qu'elle sera informée de la
      difficulté, à l'envoi de ces documents sous format papier.
      <br> Le Service peut également permettre à l’Utilisateur de transmettre, sous forme électronique via le Formulaire
      de contact ou toute autre rubrique du Service (« Mon profil » par exemple), tout document sollicité par
      Boost’Epargne dans le cadre de la relation contractuelle tels que des justificatifs permettant la prise en compte
      d’une mise à jour des données et informations personnelles de l’utilisateur.
      <br> L’Utilisateur est informé que la transmission de documents sous forme électronique au sein du Service ne
      saurait valoir validation de ceux-ci par Boost’Epargne qui procédera à l’ensemble des vérifications d’usage
      postérieurement à leur transmission. Il appartient à l’Utilisateur de vérifier la qualité des documents
      électroniques en veillant notamment à ce qu'ils ne comportent pas de virus ou autres logiciels malveillants.
      Boost’Epargne se réserve le droit de supprimer les documents électroniques transmis qui menaceraient directement
      ou indirectement l'intégrité de son système d'information. Il appartient en conséquence à l’Utilisateur de mettre
      en œuvre les mesures adéquates afin de préserver l'intégrité de son poste informatique. En tout état de cause,
      Boost’Epargne ne saurait être tenue responsable en cas de dommages causés au poste informatique de l’Utilisateur.
    </p>
    <h3 #p22><span>2.2</span>La signature électronique</h3>
    <p>Le Service peut permettre à l’Utilisateur d’accéder à une fonctionnalité de signature électronique des contrats
      et des formalités médicales des contrats de Prévoyance par Boost’Epargne. Cette signature électronique intervient
      à l’issue d’un entretien ayant été préalablement réalisé entre l’Utilisateur et Boost’Epargne sur le produit ou
      service concerné, au cours duquel il aura été convenu que le document contractuel (dont la signature est
      nécessaire pour que l’Utilisateur puisse bénéficier du contrat d’assurance concerné) serait mis à la disposition
      de l’Utilisateur pour signature électronique dans son espace personnel.
      <br> Une signature électronique via le Service est toujours précédée par l’acceptation des modalités applicables à
      la conclusion de contrats sous forme électronique par l’Utilisateur. Ce document a notamment pour objet de définir
      et de présenter aux Utilisateurs du Service les modalités de conclusion et de signature de Document contractuel
      sous forme électronique entre Boost’Epargne et eux.
    </p>

  </section>

  <section>
    <h2 #p3><span>3.</span>Tarification</h2>
    <p>
      Les Services proposés par Boost’Epargne sont fournis aux tarifs en vigueur sur son site internet et selon le devis
      établi par Boost’Epargne, lors de l'enregistrement de la commande par Boost’Epargne. Les prix sont exprimés en
      Euros, HT et TTC.
      <br>Les tarifs tiennent compte d'éventuelles réductions qui seraient consenties par Boost’Epargne dans les
      conditions précisées sur son site internet.
      <br>Ces tarifs sont fermes et non révisables pendant leur période de validité, telle qu'indiqué sur son site
      internet Boost’Epargne se réservant le droit, hors cette période de validité, de modifier les prix à tout moment.
      <br>Ils ne comprennent pas les frais de traitement et de gestion, qui sont facturés en supplément, dans les
      conditions indiquées sur son site internet et calculés préalablement à la passation de la commande.
      <br>Le paiement demandé au Client correspond au montant total de l'achat, y compris ces frais.
      <br>Une facture est établie par le Prestataire et remise au Client lors de la fourniture des Services commandés.

    </p>
  </section>




  <section>
    <h2 #p4><span>4.</span>Droits de propriété intellectuelle</h2>

    <p>La présente application et l’ensemble des éléments y figurant (informations, données, texte, sons, images,
      dessins, graphismes, signes distinctifs, logos, marques, etc.) sont la propriété exclusive de l’éditeur ou de ses
      partenaires.
      <br> L’ensemble de ces éléments est soumis aux dispositions du Code de la propriété intellectuelle et, à ce titre,
      est protégé contre toute utilisation non autorisée par la loi ou les présentes conditions d’utilisation.
      <br> Toute autre reproduction, représentation ou diffusion, en tout ou partie, du contenu de cette application,
      sur quelque support ou par tout procédé que ce soit, est interdite. Le non-respect de cette interdiction constitue
      une contrefaçon susceptible d’engager la responsabilité civile et pénale du contrefacteur.
      <br> <span class="danger"> Il est notamment strictement interdit d’utiliser ou de reproduire le nom « LA MEDICALE
        » </span>et/ou son logo,
      seuls ou associés, à quelque titre que ce soit, et notamment à des fins publicitaires, sans l’accord préalable
      écrit de Boost’Epargne.
      <br> Il est également interdit à l’Utilisateur de décompiler ou de désassembler le site internet, de l’utiliser à
      des fins commerciales ou de représentation publique ou de porter atteinte de quelconque manière aux droits
      d’auteur et de propriété intellectuelle de Boost’Epargne ou de ses partenaires.
    </p>

  </section>




  <section>
    <h2 #p5><span>5.</span>Protection des données personnelles / secret professionnel</h2>
    <h3 #p51><span>5.1</span>Protection des données personnelles</h3>
    <p>
      L’Utilisateur du Service est susceptible de communiquer à Boost’Epargne, éditeur du Service, des données à
      caractère personnel le concernant en utilisant les différents supports de collecte, outils et fonctionnalités mis
      à sa disposition sur le Service. Les données à caractère personnel, collectées dans l’espace client, en qualité de
      responsable de traitement, sont obligatoires pour les finalités précisées ci-après, ainsi que pour satisfaire à
      des obligations légales ou permettre à Boost’Epargne de poursuivre un intérêt légitime.
      <br>Les données collectées pourront faire l'objet de traitement, automatisé ou non, conformément au règlement
      général sur la protection des données « RGPD » du 27 avril 2016 et dans les conditions attachées à chaque support
      de collecte ou fonctionnalités, décrites dans les présentes CGU et le présent article.
    </p>

    <h4 #p511><span>5.1.1</span>Responsable du traitement et finalités</h4>
    <p>
      Les données à caractère personnel collectées via les différentes fonctionnalités ou supports de collecte de
      données du Service le sont par Boost’Epargne, éditeur du Service et agissant en qualité de responsable du
      traitement au sens du RGPD, sauf lorsqu’il sera expressément mentionné sur le support de collecte des données que
      le responsable de traitement est un tiers. Dans ce cas, conformément à la loi le responsable de traitement
      précisera à l’Utilisateur les caractéristiques du traitement qu’il met en œuvre sous sa seule responsabilité.
      <br>Dans le cadre de l’utilisation du Service, les données à caractère personnel que l’Utilisateur communique sont
      collectées et traitées par Boost’Epargne pour créer le Profil, permettre l’accès et l’utilisation du Service,
      répondre à ses demandes exprimées via les formulaires, outils et fonctionnalités mis à sa disposition, exécuter
      une obligation contractuelle ou précontractuelle à sa demande, produire des statistiques d’utilisation, résoudre
      des incidents fonctionnels, et respecter les obligations légales du responsable de traitement ou satisfaire la
      poursuite de l’intérêt légitime de l’éditeur du site ou d’un tiers sans méconnaitre les droits de l’utilisateur.
      <br>Sur le fondement juridique de l’intérêt légitime ou du consentement selon le cas, les données sont
      susceptibles d’être traitées à des fins de prospection commerciale, sous réserve du respect de la règlementation
      applicable en la matière (droit d’opposition pour la prospection par voie postale et téléphonique – consentement
      préalable pour la prospection réalisée par voie électronique). Par ailleurs, les cookies présents sur le Service
      ont pour finalité de permettre le bon fonctionnement du Service et de mesurer l’audience du Service.
      <br>Lorsque l’Utilisateur est identifié sur le Service en tant que client de Boost’Epargne, les données collectées
      peuvent être utilisées par Boost’Epargne dans le cadre de la gestion de la relation contractuelle. L’information
      relative aux traitements des données que l’Utilisateur communique figure alors dans la Politique de protection des
      données personnelles de Boost’Epargne

    </p>
    <h4 #p512><span>5.1.2</span>Caractéristiques sur l'usage et la collecte de données</h4>
    <p>

      L’Utilisateur n'est pas obligé de répondre à toutes les questions qui lui sont posées.
      <br> Le caractère obligatoire de la communication des informations est indiqué par la présence d'un astérisque. Le
      défaut de communication de ces données aura pour conséquence l'impossibilité pour Boost’Epargne de traiter la
      demande de l’Utilisateur ou de pouvoir utiliser le Service.

    </p>

    <h4 #p513><span>5.1.3</span>Durée de conservation des données</h4>

    <p>
      Les données personnelles de l’Utilisateur sont conservées et traitées pour la durée nécessaire à la réalisation de
      la finalité poursuivie et au maximum pour les durées mentionnées dans le présent article :
      <br>13 mois à compter du dépôt des cookies dans l’équipement de l’Utilisateur ;
      <br>25 mois pour les données de connexion (adresse IP, logs, etc.…)
      <br>Durée de la relation contractuelle au titre du service augmenté de la durée de prescription légale (5 ans)
      pour l’ensemble des autres données.

    </p>
    <h4 #p514><span>5.1.4</span>Modalités d'exercice des droits de l’Utilisateur</h4>

    <p>
      L’Utilisateur dispose de droits sur les données personnelles le concernant que Boost’Epargne collecte et traite
      dans le cadre de l’utilisation du Service et des demandes de l’Utilisateur. Ces droits, qui peuvent être exercés
      dans les conditions prévues par la règlementation, sont les suivants :

    </p>
    <ul>
      <li>un droit d'accès, de rectification et de d’effacement des données (inexactes, incomplètes, équivoques, ou
        périmées)</li>
      <li>un droit d’opposition au traitement des données à tout moment dans le cadre de la prospection commerciale</li>
      <li>un droit à la limitation du traitement des données</li>
      <li>un droit à la portabilité des données</li>
      <li>un droit de retirer votre consentement à tout moment</li>
      <li>un droit d'introduire une réclamation auprès d'une autorité de contrôle</li>
    </ul>
    <p>
      L’Utilisateur peut exercer l’ensemble de ces droits (en justifiant de son identité) par email adressé à
      dpo&#64;boost-epargne.com ou par courrier simple et sans frais envoyé à Boost’Epargne – 48 rue Danton – 92300
      Levallois. Les frais de timbre seront remboursés sur simple demande.
      <br> L’Utilisateur peut, en cas de contestation, former une réclamation auprès de la CNIL dont le site internet
      est accessible à l'adresse suivante <a href="http:// www.cnil.fr">cnil.fr</a> et le siège est situé 3 Place de
      Fontenoy, 75007 Paris.

    </p>
    <h4 #p515><span>5.1.5</span>Destinataires des données</h4>
    <p>Les données à caractère personnel de l’Utilisateur sont susceptibles d'être transmises aux destinataires
      mentionnés à l’article 5-2 « Secret professionnel » des présentes CGU.</p>
    <h4 #p516><span>5.1.6</span>Utilisation de vos données à des fins de prospection</h4>
    <p>
      Conformément à la réglementation applicable, toute prospection par automate d'appel, courrier électronique,
      sms/mms ou fax, ainsi que toute cession ou location des données à des fins de prospection utilisant ces moyens, ne
      seront réalisées qu'avec le consentement préalable de l'Utilisateur.
      <br> Si l’utilisateur accepte de recevoir de tels messages de prospection de Boost’Epargne en cochant les cases
      d'acceptation prévues à cet effet sur le Service, il sera possible à tout moment de revenir sur ce consentement,
      et notamment dans chacun des courriers électroniques de prospection qui offrira la possibilité de s’opposer pour
      le futur à l'envoi de nouveaux courriers électroniques de prospection par Boost’Epargne.
      <br> Par ailleurs, si l’Utilisateur ne souhaite pas faire l'objet de prospection commerciale par téléphone, il
      peut s’inscrire sur la liste d'opposition Bloctel (plus d'informations sur le site internet bloctel.gouv.fr.).
      <br> A tout moment et sans frais, l’utilisateur peut s’opposer à toute prospection par d'autres moyens en écrivant
      aux contacts mentionnés au paragraphe « Modalités d'exercice de vos droits ». Les frais de timbre seront
      remboursés sur simple demande.

    </p>

    <h3 #p52><span>5.2</span>Secret professionnel</h3>
    <p>
      Les informations, opérations et données personnelles détenues par Boost’Epargne dans le cadre de l’utilisation du
      Service sont couvertes par le secret professionnel auquel Boost’Epargne est tenue.
      <br> Toutefois, conformément aux conditions générales du contrat que l’Utilisateur a préalablement souscrit, pour
      satisfaire aux obligations légales et réglementaires, Boost’Epargne est parfois tenue de communiquer des
      informations aux autorités judiciaires ou administratives légalement habilitées.
      <br> En outre, l’Utilisateur autorise expressément Boost’Epargne à partager les données le concernant et leurs
      mises à jour avec les tiers suivants : l’organe central de Boost’Epargne, afin que celui-ci puisse satisfaire à
      ses obligations légales et règlementaires, notamment en matière de déclarations prudentielles auprès de toute
      autorité ou tout régulateur compétent ;

    </p>
    <ul class="letterList denger">
      <li>les médiateurs, auxiliaires de justice et officiers ministériels dans le cadre de leurs missions de
        recouvrement de créances, ainsi que les personnes intervenant dans le cadre de la cession ou du transfert de
        créances ou de contrats
      </li>
      <li>les partenaires de Boost’Epargne, pour permettre aux clients de bénéficier des avantages du partenariat auquel
        elle a adhéré, le cas échéant, et ce dans le cadre exclusif des accords de partenariat
      </li>
      <li>les sous-traitants de Boost’Epargne et notamment ceux participant à la gestion du compte et ce pour les seuls
        besoins des travaux de sous-traitance</li>
    </ul>

    <p> La liste des destinataires d’informations concernant le Client pourra lui être communiquée sur simple demande de
      sa part à Boost’Epargne.</p>

  </section>




  <section>
    <h2 #p6>6. Responsabilité de l’Utilisateur</h2>
    <p>
      L’Utilisateur est responsable de la confidentialité de l’adresse de courrier électronique renseignée et du mot de
      passe « Compte Utilisateur » choisi pour accéder à l’Espace personnel. L’Utilisateur peut également, à tout
      moment, modifier ou effacer les données de l’application (menu « Préférences », « Supprimer mon profil » pour
      supprimer tout ou partie des données).
      <br> MISES EN GARDE : l’attention de l’Utilisateur est attirée sur la nécessité de déconnexion à l’Espace
      personnel dès la fin de chaque utilisation du service, ainsi que sur la protection de ses codes confidentiels
      (regards indiscrets lors de la composition de ses codes confidentiels par exemple, vols de portable, stockage des
      codes en clair, etc.). L’Utilisateur reconnaît avoir été informé qu’à défaut de mettre fin à la navigation dans
      l’application, son contenu et notamment les données relatives à ses contrats d’assurance seront consultables par
      tout tiers détenteurs du terminal mobile par suite d’une non surveillance, d’une perte ou d’un vol de celui-ci.
      L’Utilisateur reconnaît qu’en pareilles circonstances, et en l’absence de faute commise par Boost’Epargne, la
      responsabilité de celle-ci ne pourra pas être engagée pour violation du secret professionnel.

    </p>
  </section>

  <section>
    <h2 #p7>7. Modification du service</h2>
    <p>Boost’Epargne peut, notamment pour des raisons techniques, modifier les conditions d’accès et d’utilisation du
      Service. Boost’Epargne peut également proposer de nouvelles fonctionnalités.
      <br> Boost’Epargne se réserve le droit de bloquer l’accès au Service pour des raisons de sécurité. Dans cette
      situation, une page de maintenance sera affichée à l’Utilisateur.
    </p>
  </section>




  <section>
    <h2 #p8>8. Clôture du service</h2>
    <p>
      L'accès au Service par l’Utilisateur, ne sera plus possible à l’issue d’une période de 5 ans après la résiliation
      de l'ensemble des contrats d’assurance de l’Utilisateur, accessibles via le Service.
      <br> Pour chaque contrat, l’accès aux documents mis à disposition ou signés via l’espace client ne sera plus
      possible à l’issue d’une période de 5 ans après la résiliation du contrat d’assurance concerné.

    </p>
  </section>




  <section>
    <h2 #p9>9. Renseignement, réclamation et médiation</h2>
    <h3 #p91>9.1 Renseignements, réclamations</h3>
    <p>
      L’agence est à la disposition de l’Utilisateur pour lui fournir tous les renseignements qu’il pourrait souhaiter
      sur le fonctionnement de son compte et répondre à ses éventuelles réclamations. Dans ce dernier cas, l’Utilisateur
      a aussi la possibilité, en écrivant à l’adresse de Boost’Epargne, de faire appel au Siège social, 48 rue Danton –
      92300 Levallois qui s’efforcera de trouver la meilleure solution à son différend.


    </p>
    <h3 #p92>9.2 Saisie du médiateur </h3>
    <p>
      Les dispositions du présent article s’appliquent uniquement aux Utilisateurs personnes physiques n’agissant pas
      pour leurs besoins professionnels. L’Utilisateur a la possibilité de s’adresser gratuitement à un Médiateur en
      écrivant à l’adresse suivante : La Médiation de l'Assurance - TSA 50110 - 75441 Paris Cedex 09 
      <br> Le Médiateur adressera à l’Utilisateur un document lui permettant d’exposer l’objet de sa réclamation et lui
      indiquera les prochaines étapes de la procédure. De plus, l’Utilisateur peut se renseigner sur le déroulement de
      cette procédure, soit en agence, soit sur le site Internet de Boost’Epargne. Aux fins de cette procédure,
      l’Utilisateur autorise expressément Boost’Epargne à communiquer au Médiateur tous les documents et informations
      utiles à l’accomplissement de sa mission. L’Utilisateur délie Boost’Epargne du secret professionnel le concernant,
      pour les besoins de la médiation.


    </p>
  </section>




  <section>
    <h2 #p10>10. Loi applicable et compétence du tribunal</h2>
    <p>
      Les présentes CGU sont soumises au droit français. Tout différend portant sur leur validité, leur interprétation
      ou leur exécution relèvera, faute d’être résolu à l’amiable entre Boost’Epargne et l’Utilisateur, des tribunaux
      français compétents.

    </p>
  </section>

</article>
