import { AsyncPipe } from "@angular/common";
import { Component, inject } from "@angular/core";
import { Router, RouterModule } from "@angular/router";
import { BudgetinsightService } from "@services/API/budgetinsight.service";
import { ThemeService } from "@services/utils/theme.service";
import { StoreService } from "@src/app/core/services/store.service";
import { NavigationService } from "@src/app/core/services/utils/navigation.service";
import { IssueIconComponent } from "../issue-icon/issue-icon.component";

@Component({
	standalone: true,
	imports: [AsyncPipe, RouterModule, IssueIconComponent],
	selector: "app-header",
	templateUrl: "./header.component.html",
	styleUrls: ["./header.component.scss"]
})
export class HeaderComponent {
	private NAV = inject(NavigationService);
	private THEME = inject(ThemeService);
	private router = inject(Router);
	private BI = inject(BudgetinsightService);
	private STORE = inject(StoreService);

	logoSrc: string = `./assets/images/logo/${this.THEME.active.logo_top}`;
	connectionIssuesIcon$ = this.STORE.connectionIssuesIcon$;
	goHome() {
		this.router.navigate(["./"]);
	}
	openConnectionsIssuesModal() {
		this.NAV.showConnectionsIssues = true;
	}
	addContract(evt: Event): void {
		this.BI.openWebView();
	}
}
