import { Component, model } from "@angular/core";

@Component({
	standalone: true,
	selector: "popin",
	template: `
		@if (show()) {
		<div class="popInOverlay popin" (click)="close()">
			<article (click)="clickModal($event)">
				<span class="material-symbols-rounded close" (click)="close()">
					close
				</span>
				<ng-content></ng-content>
			</article>
		</div>
		}
	`
})
export class PopinComponent {
	show = model<boolean>(false);
	close() {
		this.show.set(false);
	}
	clickModal(e: Event) {
		e.stopPropagation();
	}
}
