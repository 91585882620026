import { AsyncPipe } from "@angular/common";
import {
	Component,
	ElementRef,
	OnInit,
	Signal,
	inject,
	viewChild
} from "@angular/core";
import { ActivatedRoute, RouterOutlet } from "@angular/router";
import { User } from "@interfaces";
import { ContractService } from "@services/API/contract.service";
import { UserService } from "@services/API/user.service";
import { ConnectionService } from "@services/connection.service";
import { ThemeService } from "@services/utils/theme.service";
import { NavigationService } from "@src/app/core/services/utils/navigation.service";
import browserUpdate from "browser-update";
import { Observable, take } from "rxjs";
import { FooterComponent } from "./shared/components/footer/footer.component";
import { HeaderComponent } from "./shared/components/header/header.component";
import { LoadingBoxComponent } from "./shared/components/loading-box.component";
import { PopinComponent } from "./shared/components/popin/popin.component";
import { AccountFormComponent } from "./shared/forms/account-form/account-form.component";
import { ConnectionsIssuesComponent } from "./shared/forms/connections-Issues/connections-Issues.component";

@Component({
	selector: "app-root",
	templateUrl: "./app.component.html",
	standalone: true,
	imports: [
		HeaderComponent,
		RouterOutlet,
		FooterComponent,
		PopinComponent,
		ConnectionsIssuesComponent,
		AccountFormComponent,
		AsyncPipe,
		LoadingBoxComponent
	]
})
export class AppComponent implements OnInit {
	private USER = inject(UserService);
	private CONTRACT = inject(ContractService);
	private CONNECT = inject(ConnectionService);
	private NAV = inject(NavigationService);
	private THEME = inject(ThemeService);
	private route = inject(ActivatedRoute);
	private mainContent = viewChild.required<ElementRef>("mainContent");
	public openModal: Signal<boolean> = this.NAV.openModal;
	public showConnectionsIssue: Signal<boolean> = this.NAV.showConnectionsIssues;
	public showContractSettings = this.CONTRACT.showContractSettings;
	public profile$: Observable<User> = this.USER.profile$;
	public loggedIn$: Observable<boolean> = this.CONNECT.auth$;

	constructor() {
		this.CONNECT.authentification();
	}

	ngOnInit() {
		// browser update ribbon
		browserUpdate({
			required: {
				e: 79,
				// i: 10,
				f: -3,
				o: -3,
				s: 10.1,
				c: "64.0.3282.16817",
				samsung: 7.0
			},
			insecure: true,
			// test: true,
			style: "bottom"
		});

		//this.THEME.setActiveTheme("axa");
		this.THEME.setActiveTheme("default");

		this.route.queryParamMap.subscribe(params => {
			const code = params.get("code");
			if (!!code) {
				console.log("------- code ===> ", code);
				this.CONTRACT.exchangeCodeBiToken(code)
					.pipe(take(1))
					.subscribe((retour: any) => console.log("retour: ", retour));
			}
		});
	}

	// Notify MAIN service when modal is open/closed
	public deactivateModal() {
		this.NAV.openModal = false;
	}
	public activateModal() {
		this.NAV.openModal = true;
	}
	public closeModal() {
		this.NAV.showConnectionsIssues = false;
		this.CONTRACT.showContractSettings = null;
	}

	onActivate(_event: any): void {
		// Scrolling back to the top on every route click
		this.mainContent().nativeElement.scrollTop = 0;
	}
}
