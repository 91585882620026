import { Injectable } from "@angular/core";
import {
	CardInfo,
	CardMarketing,
	CardTipImage,
	Link
} from "@src/app/shared/interface";
import { environment } from "@src/environments/environment";
import { Apollo, ApolloBase, gql } from "apollo-angular";
import { combineLatest, map, tap } from "rxjs";

@Injectable({ providedIn: "root" })
export class StrapiService {
	private css = "color:#8bc24b;font-weight:bold";
	private apollo: ApolloBase;
	constructor(private apolloProvider: Apollo) {
		this.apollo = this.apolloProvider.use("strapiAPI");
	}

	public getAllMarketingCards() {
		return this.apollo
			.watchQuery<any>({
				query: getAllMarketingCards,
				fetchPolicy: "cache-and-network"
			})
			.valueChanges.pipe(
				tap((tips: any) => {
					console.log("%cgetAllMarketingCards: ", this.css, tips);
				}),
				map((tips: any) => tips.data.carteMarketings.data),
				tap((tips: any) => {
					console.log("%cMARKETING CARDS DATA: ", this.css, tips);
				}),
				map(data =>
					data.map(
						(d: any) =>
							({
								cardContentType: "marketing",
								id: d.id,
								title: d.attributes.titre,
								body: d.attributes.corps,
								type: d.attributes.type ?? "classique",
								ctaPredefini: d.attributes.cta_predefini ?? null,
								cta: (d.attributes.lien as Link) ?? null,
								image: {
									url: this.imgURL(
										d.attributes.image.data?.attributes.url ?? null
									)
								} as CardTipImage
							} as CardMarketing)
					)
				),
				tap((tips: any) => {
					console.log("%cCLEAN: ", this.css, tips);
				})
			);
	}
	private imgURL = (url: string): string =>
		url ? `${environment.strapiRoot}${url}` : "";

	public getAllInfoCards() {
		return this.apollo
			.watchQuery<any>({
				query: getAllInfoCards,
				fetchPolicy: "cache-and-network"
			})
			.valueChanges.pipe(
				map((tips: any) => tips.data.carteInfos.data),
				tap((tips: any) => {
					console.log("%cINFO CARDS DATA: ", this.css, tips);
				}),
				map(data =>
					data.map(
						(d: any) =>
							({
								cardContentType: "info",
								id: d.id,
								title: d.attributes.titre,
								body: d.attributes.corps,
								type: d.attributes.type ?? "classique",
								image: d.attributes.image?.data
									? ({
											url: this.imgURL(
												d.attributes.image?.data.attributes.url ?? null
											),
											mode: d.attributes.image_position
									  } as CardTipImage)
									: null
							} as CardInfo)
					)
				),
				tap((tips: any) => {
					console.log("%cCLEAN: ", this.css, tips);
				})
			);
	}

	public getAllTips() {
		return combineLatest([
			this.getAllMarketingCards(),
			this.getAllInfoCards()
		]).pipe(
			map(tips => tips.flat()),
			tap((tips: any) => {
				console.log("%cALL CLEAN TIPS: ", this.css, tips);
			})
		);
	}
}

export const getAllMarketingCards = gql`
	query CarteMarketings {
		carteMarketings {
			data {
				id
				attributes {
					titre
					corps
					type
					image {
						data {
							id
							attributes {
								name
								url
							}
						}
					}
					cta_predefini
					lien {
						Label
						Url
						id
					}
				}
			}
		}
	}
`;

export const getAllInfoCards = gql`
	query CarteInfos {
		carteInfos {
			data {
				id
				attributes {
					titre
					corps
					type
					image_position
					image {
						data {
							id
							attributes {
								name
								url
							}
						}
					}
				}
			}
		}
	}
`;
