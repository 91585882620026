/// <reference types="@angular/localize" />

import { registerLocaleData } from "@angular/common";
import {
	provideHttpClient,
	withInterceptorsFromDi
} from "@angular/common/http";
import localeFr from "@angular/common/locales/fr";
import { LOCALE_ID, enableProdMode, importProvidersFrom } from "@angular/core";
import { bootstrapApplication } from "@angular/platform-browser";
import { environment } from "@environments/environment";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { OAuthModule } from "angular-oauth2-oidc";
import { ApolloModule } from "apollo-angular";
// import "material-symbols";
import { AppRoutingModule } from "./app/app-routing.module";
import { AppComponent } from "./app/app.component";
import { GraphQLModule } from "./app/core/graphql/graphql.module";
import { effects } from "./app/shared/store/root.effects";
import { reducers } from "./app/shared/store/root.reducer";

registerLocaleData(localeFr);

if (environment.production) {
	enableProdMode();
}

bootstrapApplication(AppComponent, {
	providers: [
		importProvidersFrom(
			GraphQLModule,
			AppRoutingModule,
			ApolloModule,
			OAuthModule.forRoot({
				resourceServer: {
					sendAccessToken: true,
					allowedUrls: [environment.apiUrl]
				}
			}),
			// NGRX
			StoreModule.forRoot(reducers),
			StoreDevtoolsModule.instrument({
				maxAge: 25, // Retains last 25 states
				logOnly: environment.production // Restrict extension to log-only mode
			}),
			EffectsModule.forRoot(effects)
		),
		{ provide: LOCALE_ID, useValue: "fr-FR" },
		provideHttpClient(withInterceptorsFromDi())
	]
}).catch(err => console.log(err));
